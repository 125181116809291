export default function Configs() {
  const tablePageColumns = [
    {
      label: "检测时间",
      prop: "checkTime",
      align: "center",
      show: true,
      width:180
    },
    {
      label: "车道",
      prop: "laneNum",
      align: "center",
      show: true
    },
    {
      label: "车牌",
      prop: "carNum",
      align: "center",
      show: true
    },
    {
      label: "车牌颜色",
      prop: "carColor",
      align: "center",
      show: true
    },
    {
      label: "车重(kg)",
      prop: "grossWeight",
      align: "center",
      show: true
    },
    {
      label: "轴数",
      prop: "axleNum",
      align: "center",
      show: true
    },
    {
      label: "超限类型",
      prop: "overloadType",
      align: "center",
      show: true
    },
    {
      label: "车速(km/h)",
      prop: "speed",
      align: "center",
      show: true
    },
    {
      label: "操作",
      name:'custom',
      align: "center",
      show: true
    },
  ];
  return {
    tablePageColumns
  };
}
