<template>
  <div class="single-traffic-flow manage-scroll">
    <div class="common-module relative">
      <div class="module-title flex justify-between items-center">
        <div class="flex items-center">
          <img
              src="../../../../../assets/images/admin/single/icon/icon_tongji.png"
              alt=""
          />
          <span class="title-text ml-3">超限统计列表</span>
        </div>
      </div>
      <div class="search-wrapper">
        <c-search
            v-model="form"
            labelWidth="10px"
            :searchItems="searchItems"
            @search="handleSearch"
        >
        </c-search>
<!--        <el-button class="all-export" type="primary" @click="handleExportAll">批量导出</el-button>-->
      </div>
      <div class="module-content table-wrapper">
        <c-table
            :data="listData"
            :tableColumns="tableColumns"
            :stripe="true"
            :index="true"
            :pagination="pageParams"
            @change="getData"
        >
          <template slot="twoAxle" slot-scope="scope">
            <span class="opt-text" @click="getDetailTable(scope,'twoAxle')">{{scope.row.twoAxle}}</span>
          </template>
          <template slot="threeAxle" slot-scope="scope">
            <span class="opt-text" @click="getDetailTable(scope,'threeAxle')">{{scope.row.threeAxle}}</span>
          </template>
          <template slot="fourAxle" slot-scope="scope">
            <span class="opt-text" @click="getDetailTable(scope,'fourAxle')">{{scope.row.fourAxle}}</span>
          </template>
          <template slot="fiveAxle" slot-scope="scope">
            <span class="opt-text" @click="getDetailTable(scope,'fiveAxle')">{{scope.row.fiveAxle}}</span>
          </template>
          <template slot="moreAxle" slot-scope="scope">
            <span class="opt-text" @click="getDetailTable(scope,'moreAxle')">{{scope.row.moreAxle}}</span>
          </template>
          <template slot="weightLimit" slot-scope="scope">
            <span class="opt-text" @click="getDetailTable(scope,'weightLimit')">{{scope.row.weightLimit}}</span>
          </template>
          <template slot="sum" slot-scope="scope">
            <span class="opt-text" @click="getDetailTable(scope,'sum')">{{scope.row.sum}}</span>
          </template>
        </c-table>
      </div>
    </div>
    <el-dialog
        :title="dialog.title"
        :visible.sync="dialogVisible"
        width="1100px"
        :before-close="handleClose">
      <el-button style="margin-bottom: 10px" type="primary" @click="handleExport">导出</el-button>
      <div class="module-content table-wrapper">
        <div class="table-wrapper">
          <c-table
              :data="pageData"
              :tableColumns="tablePageColumns"
              :stripe="true"
              :index="true"
              :pagination="page"
              @change="getDetailList"
          >
            <template v-slot:custom="{ row }">
              <span class="opt-detail" @click="$router.push({path:'/single/traffic/flow/detail/' + row.id,query:{type:1}})">详情</span>
            </template>
          </c-table>
        </div>
      </div>

    </el-dialog>

  </div>
</template>

<script>
  // import common from "../../../../../../assets/js/common";
  import Configs from "./config/table";
  import ConfigTables from "./config/detailTable";
  import common from "../../../../../assets/js/common";
  export default {
    name: "index1",
    data() {
      let {searchItems, tableColumns} = Configs(this);
      let {tablePageColumns} = ConfigTables(this);
      return {
        searchItems,
        tableColumns,
        tablePageColumns,
        form: {
          date: [],
          sign: '2'
        },
        page:{
          pageSize: 10,
          pageNum: 1,
          total: 0,
        },
        pageParams:{
          pageSize: 10,
          pageNum: 1,
          total: 0,
        },
        listData: [],
        pageData:[],
        dialog:{
          title:''
        },
        detail:null,
        dialogVisible:false,
        day:''
      }
    },
    created() {
      let date = this.$moment(new Date()).format('YYYY-MM-DD')
      let ago = this.$moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD')
      this.form.date = [ago, date]
      this.getData()
    },
    methods: {
      getData() {
        let params = {
          startDay: this.form.date[0],
          endDay: this.form.date[1],
          sign: this.form.sign,
          pageSize: this.pageParams.pageSize,
          pageNum: this.pageParams.pageNum,
        }
        this.$http.post('/car/statistics/page', params).then(res => {
          if (res.success) {
            this.listData = res.data.list
            this.pageParams.total = res.data.total
          }
        })
      },
      handleExportAll(){

      },
      handleExport() {
        let params = {
          day:this.detail.row.checkDate,
          column:this.column,
        }
        this.$http.post('/car/overload/statistical/detail/export', params,{contentType:"application/x-www-form-urlencoded",
          responseType: "blob"}
        ).then(res => {
          common.blobDownLoad(res)
        })
      },
      handleSearch() {
        this.getData()
      },
      getDetailTable(data,column) {
        this.dialog.title = data.column.label + '：' + data.row[column] + '辆'
        this.detail = data
        this.column = column
        this.getDetailList()
      },
      getDetailList(){
        let params = {
          day:this.detail.row.checkDate,
          column:this.column,
          pageNum:this.page.pageNum,
          pageSize:this.page.pageSize
        }
        this.$http.post('/car/overload/statistical/detail',params).then(res => {
          if (res.success){
            this.pageData = res.data.list
            this.page.total = res.data.total
          }
          if (!this.dialogVisible){
            this.dialogVisible = true
          }
        })
      },
      handleClose(done) {
        done();
      }
    }
  }
</script>

<style scoped>
  .btn-export {
    background-color: #e6effb;
    border: 1px solid #3382ed;
    border-radius: 2px;
    color: #3382ed;
  }
  /*/deep/ .el-table--scrollable-y .el-table__body-wrapper {*/
  /*  height: auto !important;*/
  /*}*/
  .opt-text {
    cursor: pointer;
    text-decoration: underline
  }
  /deep/ .el-dialog__body{
    padding: 10px 20px;
  }
  .search-wrapper{
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
  }
  .all-export{
    background-color: #e6effb;
    border: 1px solid #3382ed;
    border-radius: 2px;
    color: #3382ed;
    margin-left: 10px;
    height: 36px;
  }
</style>