export default function Configs() {
  const searchItems = [
    {
      label: "",
      span: 15,
      placeholder: "",
      model: "date",
      type: "daterange",
      labelWidth:0,
    },
    {
      label: "",
      span: 9,
      type: "radioBtn",
      model: "sign",
      options:[
        {key:'2',label:'全桥'},
        {key:'0',label:'左幅'},
        {key:'1',label:'右幅'}
      ]
    },
  ]
  const tableColumns = [
    {
      label: "统计日期",
      prop: "checkDate",
      align: "center",
      show: true,
      sortable:true
    },
    {
      label: "两轴超限(>18t)",
      name:'twoAxle',
      align: "center",
      show: true,
      sortable:true
    },
    {
      label: "三轴超限(>27t)",
      name: "threeAxle",
      align: "center",
      show: true,
      sortable:true
    },
    {
      label: "四轴超限(>36t)",
      name: "fourAxle",
      align: "center",
      show: true,
      sortable:true
    },
    {
      label: "五轴超限(>43t)",
      name: "fiveAxle",
      align: "center",
      show: true,
      sortable:true
    },
    {
      label: "六轴及以上超限(>49t)",
      name: "moreAxle",
      align: "center",
      show: true,
      sortable:true
    },
    {
      label: "总重(>49t)",
      name: "weightLimit",
      align: "center",
      show: true,
      sortable:true
    },
    {
      label: "总计",
      name: "sum",
      align: "center",
      show: true,
      sortable:true
    },
  ];
  return {
    searchItems,
    tableColumns,
  };
}
